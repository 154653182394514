var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{attrs:{"full":"","title":_vm.$route.meta.title,"action":{
    text: '削除',
    type: 'danger'
  },"icon":_vm.back.name ? 'close' : 'arrow-left'},on:{"action":_vm.removeItem,"dismiss":function($event){_vm.back.name ?
    _vm.$router.push(_vm.back).catch(() => {}) :
    _vm.$_octPrototypeView_goBack()}},scopedSlots:_vm._u([(
      _vm.item &&
      (
        _vm.$store.state.staffs.me.isAdministrator ||
        _vm.$store.state.staffs.me.role === 1 ||
        _vm.item.status < 4 ||
        (new Date).getTime() - _vm.item.publishedAt.getTime() < 48*3600*1000
      )
    )?{key:"actions",fn:function(){return [_c('oct-button',{attrs:{"block":"","outline":"","size":"large","disabled":_vm.item.status >= 3},nativeOn:{"click":function($event){return _vm.updateState(3)}}},[_vm._v(" タブレット連携 ")]),_c('oct-button',{staticClass:"ss-treatment__action",attrs:{"size":"large","tagName":!_vm.$store.state.id || _vm.$store.state.id === '12000001' || _vm.$store.state.id === '17004207' || _vm.$store.state.id === '17703346' ? 'div' : 'button'},nativeOn:{"click":function($event){return _vm.updateState(4)}}},[_vm._v(" "+_vm._s(_vm.item.status >= 4 ? '更新' : '送信')+" ")])]},proxy:true}:null],null,true)},[(_vm.item)?_c('div',{staticClass:"ss-treatment__content"},[_c('oct-customer-card',{staticClass:"ss-treatment-intro",attrs:{"icon":_vm.customer && _vm.customer.profileIcon || undefined,"title":_vm.customer ? `${_vm.customer.name} 様` : '',"text":`${_vm.$_octPrototypeView_getDateString(_vm.item.publishedAt, true)}〜`,"meta":{
        text: _vm.item.status >= 4 ? '送信済' : '未送信',
        active: _vm.item.status < 4
      },"history":""},on:{"click:history":function($event){_vm.customer && _vm.$router.push({
        name: 'Customer',
        params: {
          customerId: _vm.customer.id
        }
      })}}}),_c('div',{staticClass:"ss-treatment__section ss-treatment__section--user"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.stylists.length
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" スタイリスト ")])]),_c('oct-list',[_vm._l((_vm.staffs(_vm.item.stylists)),function(item){return _c('oct-list-item',{key:item.id,attrs:{"media":{
            image: _vm.$_octPrototypeView_getProfileIconSafely(item.profileIcon)
          },"title":`${item.lastName || ''} ${item.firstName || ''}`,"text":"スタイリスト","removable":""},on:{"remove":function($event){return _vm.removeUser('stylists', item.id)}},nativeOn:{"click":function($event){_vm.$router.push({
            path: `${_vm.$route.path}/stylists`
          }).catch(() => {})}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true)})}),_c('oct-list-item',{attrs:{"media":{
            image: _vm.$_octPrototypeView_getProfileIconSafely()
          },"title":"スタイリストを追加"},nativeOn:{"click":function($event){_vm.$router.push({
            path: `${_vm.$route.path}/stylists`
          }).catch(() => {})}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)})],2)],1),_c('div',{staticClass:"ss-treatment__section ss-treatment__section--user"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.assistants.length
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" アシスタント ")])]),_c('oct-list',[_vm._l((_vm.staffs(_vm.item.assistants)),function(item){return _c('oct-list-item',{key:item.id,attrs:{"media":{
            image: _vm.$_octPrototypeView_getProfileIconSafely(item.profileIcon)
          },"title":`${item.lastName || ''} ${item.firstName || ''}`,"text":"アシスタント","removable":""},on:{"remove":function($event){return _vm.removeUser('assistants', item.id)}},nativeOn:{"click":function($event){_vm.$router.push({
            path: `${_vm.$route.path}/assistants`
          }).catch(() => {})}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true)})}),_c('oct-list-item',{attrs:{"media":{
            image: _vm.$_octPrototypeView_getProfileIconSafely()
          },"title":"アシスタントを追加"},nativeOn:{"click":function($event){_vm.$router.push({
            path: `${_vm.$route.path}/assistants`
          }).catch(() => {})}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)})],2)],1),_c('div',{staticClass:"ss-treatment__section"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.shampoo && !_vm.item.treatment && !_vm.item.outbath && !_vm.item.others.length
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" 使用商品 ")])]),_c('oct-list',[_c('oct-list-item',_vm._b({attrs:{"removable":!!_vm.productByID(_vm.item.shampoo)},on:{"remove":function($event){return _vm.removeProduct('shampoo')}},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories/1`,
            query: {
              prop: 'shampoo'
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)},'oct-list-item',_vm.product(_vm.item.shampoo, 1),false)),_c('oct-list-item',_vm._b({attrs:{"removable":!!_vm.productByID(_vm.item.treatment)},on:{"remove":function($event){return _vm.removeProduct('treatment')}},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories/2`,
            query: {
              prop: 'treatment'
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)},'oct-list-item',_vm.product(_vm.item.treatment, 2),false)),_c('oct-list-item',_vm._b({attrs:{"removable":!!_vm.productByID(_vm.item.outbath)},on:{"remove":function($event){return _vm.removeProduct('outbath')}},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories/3`,
            query: {
              prop: 'outbath'
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)},'oct-list-item',_vm.product(_vm.item.outbath, 3),false)),_vm._l((_vm.others),function(p,j){return _c('oct-list-item',_vm._b({key:p,attrs:{"removable":""},on:{"remove":function($event){return _vm.removeProduct('others',p)}},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories`,
            query: {
              prop: 'others',
              index: j
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true)},'oct-list-item',_vm.product(p, 4),false))}),_c('oct-list-item',{attrs:{"title":"その他","icon":"plus-circle"},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories`,
            query: {
              prop: 'others',
              index: -1
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)})],2)],1),_c('div',{staticClass:"ss-treatment__section"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.data.recommendedItem.item
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" おすすめ商品 ")])]),_c('oct-list',[(!_vm.item.data.recommendedItem.item || !this.$store.getters['products/byId'](_vm.item.data.recommendedItem.item))?_c('oct-list-item',{attrs:{"title":"商品を選ぶ","icon":"plus-circle"},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories`,
            query: {
              prop: 'recommended',
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)}):_c('oct-list-item',_vm._b({attrs:{"removable":""},on:{"remove":function($event){return _vm.removeProduct('recommended',_vm.p)}},nativeOn:{"click":function($event){return _vm.$router.push({
            path: `${_vm.$route.path}/products/categories`,
            query: {
              prop: 'recommended',
            }
          })}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,false,4039336883)},'oct-list-item',this.product(_vm.item.data.recommendedItem.item),false))],1)],1),(_vm.$_ssTreatmentView_recommendedMessage)?_c('div',{staticClass:"ss-treatment__section"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.data.recommendedItem.message
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" おすすめ商品メッセージ ")])]),_c('div',{staticClass:"ss-treatment__body"},[_c('oct-text-field',{attrs:{"rows":7,"block":"","disabled":""},scopedSlots:_vm._u([{key:"helperText",fn:function(){return [_vm._v(" 仕様変更に伴い、現在お客様には表示されておりません ")]},proxy:true}],null,false,2052043509),model:{value:(_vm.$_ssTreatmentView_recommendedMessage),callback:function ($$v) {_vm.$_ssTreatmentView_recommendedMessage=$$v},expression:"$_ssTreatmentView_recommendedMessage"}})],1)]):_vm._e(),_c('div',{staticClass:"ss-treatment__section"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.images.length
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" 施術写真 ")])]),_c('div',{staticClass:"ss-treatment-slider"},[_c('ul',{staticClass:"ss-treatment-media"},[_vm._l((_vm.item.images),function(img,i){return _c('li',{key:img.id,staticClass:"ss-treatment-media__item"},[_c('oct-media',{attrs:{"image":img,"actions":['remove'],"editable":""},on:{"remove":function($event){return _vm.removeImage(i)},"upload:complete":function($event){return _vm.$_ssTreatmentView_onUploadStatusChange($event, i)}}})],1)}),_vm._l(((_vm.item.images.length < 3 ? Array(3 - _vm.item.images.length).fill(0) : [])),function(i,j){return _c('li',{key:`p${j}`,staticClass:"ss-treatment-media__item"},[_c('oct-media',{attrs:{"image":_vm.$_ssTreatmentView_image(_vm.item.images.length + j + 1),"actions":['add'],"editable":""},on:{"upload:complete":_vm.$_ssTreatmentView_onUploadStatusChange}})],1)}),_c('li',{key:new Date().getTime(),staticClass:"ss-treatment-media__item"},[_c('oct-media',{attrs:{"actions":['new'],"editable":""},on:{"upload:complete":_vm.$_ssTreatmentView_onUploadStatusChange}})],1),_c('li',{staticClass:"ss-treatment-media__item",attrs:{"role":"separator"}})],2)]),_c('div',{staticClass:"ss-treatment__note"},[_c('p',{staticClass:"ss-treatment__note-text"},[_vm._v(" ※ブラウザ上で撮影するとカメラロールに写真が保存されません。 ")])])]),_c('div',{staticClass:"ss-treatment__section"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.videos.length
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" 施術動画 ")])]),_c('div',{staticClass:"ss-treatment-media ss-treatment-media--video"},[_c('oct-media',{attrs:{"type":"video","video":_vm.$_ssTreatmentView_video,"actions":_vm.$_ssTreatmentView_video ? ['remove'] : ['add'],"editable":""},on:{"update:video":function($event){_vm.$_ssTreatmentView_video=$event},"remove":_vm.removeVideo}})],1),_c('div',{staticClass:"ss-treatment__note"},[_c('p',{staticClass:"ss-treatment__note-text"},[_vm._v(" ※ブラウザ上で撮影するとカメラロールに写真が保存されません。 ")])])]),_c('div',{staticClass:"ss-treatment__section"},[_c('div',{staticClass:"ss-treatment-header",class:{
          'ss-treatment-header--empty': !_vm.item.message
        }},[_c('h3',{staticClass:"ss-treatment-header__primary-text"},[_c('span',{staticClass:"ss-treatment-header__icon"},[_c('oct-icon',{attrs:{"icon":"done"}})],1),_vm._v(" 一言コメント ")])]),_c('div',{staticClass:"ss-treatment__body"},[_c('oct-text-field',{attrs:{"rows":7,"block":""},model:{value:(_vm.$_ssTreatmentView_message),callback:function ($$v) {_vm.$_ssTreatmentView_message=$$v},expression:"$_ssTreatmentView_message"}})],1)])],1):_vm._e(),(_vm.item)?_c('ss-customer-mail',{ref:"$_ssTV_email",staticClass:"ss-treatment__customer-email",attrs:{"id":_vm.item.id}}):_vm._e(),_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view',{key:_vm.$route.name,staticClass:"ss-dialog__content"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }