<template>
  <div id="app">
    <oct-snackbar />

    <router-view/>
    <oct-svg/>
  </div>
</template>

<script>
import API from '@/api/index.js'
import OctSnackbar from '@/components/snackbar/OctSnackbar'
import OctSvg from '@/components/svg/OctSvg'

export default {
  name: 'App',
  components: {
    OctSnackbar,
    OctSvg
  },
  methods: {
    /** Sign out */
    signOut () {
      API.signOut()
        .catch(() => {})
        .finally(() => this.$router.replace({name:'Login'}).catch(() => {}))
    }
  },
  created () {
    // Subscribe mutation
    this.$store.subscribe(mutation => {
      // Case of error
      if (mutation.type === 'error' && mutation.payload)
        switch (mutation.payload.code) {
          case 100:
            return this.$store.dispatch('alert', mutation.payload)
          case '400':
          case '401': // Refresh token expired
          case '403':
          case 403:
            return this.signOut()
          case 404:
            this.$store.dispatch('alert', mutation.payload.message)
            return this.$router.replace({name:'Dashboard'}).catch(() => {})
          case 500:
            this.$store.dispatch('alert', mutation.payload.message)
            return this.signOut()
          default:
            console.error(mutation.payload)
            alert(mutation.payload.message)
        }
    })
  }
}
</script>

<style lang="scss">
@import 'assets/app.scss';
</style>
